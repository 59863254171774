//Custom Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

.App {
  height: 100vh;
  width: 100vw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  font-family: "Open Sans", sans-serif;
}


.register {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10vh;
  position: relative;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 0 2vmax 1vmin rgba(15, 15, 200, 0.3);
    border-radius: 2vmax;
    position: relative;
    z-index: 99;
    padding: 3vh 2vw 3vh;
  }
}

//Button
.btn {
  font-size: 21px;
  padding: 10px 20px;
  border: 0;
  background-color: #3498db;
  color: #fff;
  border-radius: 7px;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: #2386c8;
  }
  &:focus {
    outline: none;
    background-color: #360276;
  }
}

.pew {
  cursor: pointer;
  &:focus {
    color: #55a;
  }  
}
