.header-dash {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
}


.subdomain {
    position: absolute;
    top: 74px;
    left: 0px;
    right: 0;
    bottom: 0;
    background-color: white;
    transition: 0.5s;
}

.subdomain iframe {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
}

.header-dash-line {
    height: 74px;
    color: #111;
    background: #111;
    background: -webkit-linear-gradient(to top, #222, #000);
    background: linear-gradient(to top, #222, #000);
    border-radius: 0 0 0 0;
    display: flex;
}

.menu {
    float: left;
}

.header-dash-left {
    padding: 0px 0 0 0px;
    width: 80%;
}

.header-dash-right {
    text-align: right;
    padding: 16px 30px 0 0px;
    width: 20%;
}

.header-dash-combo {
    display: flex;
}


.header-dash-image {
    text-align: right;
    padding: 18px 0px 0px 0px;
    //height: 35px;
    width: 150px;
}

.header-dash-image-2 {
    text-align: right;
    padding: 5px 0px 0 0px;
    height: 70px;
    width: 70px;
}

.header-dash-title {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 0px;
    width: 30%;
}

.header-dash-title-2 {
    padding: 10px 0px 0px 0px;
    width: 50%;
}

.headerbtn-user {
    font-size: 11px;
    letter-spacing: 0.5px;
    border: 0;
    background-color: rgba(255, 255, 255, .0);
    color: #fff;
    padding: 0px 0px 0px 0px;
    border-radius: 0 0 0px 0px;
    transition: all 250ms ease-in-out;
    cursor: pointer;

    &:hover {
        color: #dda63a;
    }
}

.invisiron-version {
    display: block;
    font-size: 10px;
    color: #fff;
}

#mobile-logout {
    display: none;
}


.menu-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    appearance: none;
    display: block;
    padding: 8px 10px;
    white-space: nowrap;
    font-size: 13px;
    color: #dda63a;
    background-color: #000;
    text-decoration: none;
    border: none;
    border-width: 0;
    border: 0;
    cursor: pointer;

    &:hover {
        transition: 200ms;
        color: rgb(255, 255, 255);
    }

    &:focus {
        outline: none;
    }
}

.headerbtn {
    border: 0;
    background-color: rgba(255, 255, 255, .0);
    color: #fff;
    padding: 0px 0px 0px 0px;
    border-radius: 0 0 0 0;
    transition: all 250ms ease-in-out;

    &:focus {
        outline: none;
        background-color: #111;
    }
}

.menu-title {
    display: inline-block;
    -webkit-transform: scale(2, 1);
    /* Safari and Chrome */
    -moz-transform: scale(2, 1);
    /* Firefox */
    -ms-transform: scale(2, 1);
    /* IE 9 */
    -o-transform: scale(2, 1);
    /* Opera */
    transform: scale(2, 1);
    /* W3C */
    padding: 0px 0px 0px 0px;
    color: #fff;
    margin-left: 20px;
    white-space: nowrap;
}

.menubtn-head {
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    border: 0;
    background-color: rgba(255, 255, 255, .0);
    color: #ffd700;
    padding: 15px 13px 15px 20px;
    border-radius: 0 0px 20px 0px;
    transition: all 250ms ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: #888;
    }

    &:focus {
        outline: none;
        background-color: #111;
    }
}

.menubtn {
    text-align: left;
    font-size: 18px;
    width: 100%;
    border: 0;
    background-color: rgba(255, 255, 255, .0);
    color: #fff;
    padding: 15px 13px 15px 10px;
    border-radius: 0 20px 20px 0px;
    transition: all 250ms ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: #2386c8;
    }

    &:focus {
        outline: none;
        background-color: #360276;
    }
}


.menubtn-list {
    text-align: left;
    text-transform: uppercase;
    font-family: Lato, "Helvetica";
    padding: 8px 10px;
    font-size: 12px;
    width: 100%;
    border: 0;
    background-color: rgba(255, 255, 255, .0);
    color: #fff;
    transition: all 250ms ease-in-out;
    cursor: pointer;

    &:hover {
        color: #dda63a;
    }

    &:focus {
        outline: none;
    }
}


.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background: #111;
    background: -webkit-linear-gradient(to top, #888, #000);
    background: linear-gradient(to top, #888, #000);
    overflow-x: hidden;
    transition: 0.5s;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.usermanagement {
    display: none;
}

.dashboard {
    display: none;
}

.branding {
    display: none;
}

.brandinglist {
    display: none;
}

.training {
    display: none;
}

.trainingList {
    display: none;
}

.sidebarmenu-line {
    display: flex;
}

.partnerarea {
    display: none;
}

.partnerareaList {
    display: none;
}


.sidebarmenu-left {
    text-align: left;
    padding: 0px 0px 0px 0px;
    width: 100%;
}

.sidebarmenu-right {
    text-align: right;
    padding: 0px 0px 0px 0px;
    width: 100%;
}

.list-color {
    color: #CFB53B;

    &:hover {
        color: #29b6c0;
    }
}



.toggle,
[id^=drop] {
    display: none;
}

/* Giving a background-color to the nav container. */
nav {
    z-index: 999;
    margin: 0;
    padding: 0px 40px;
    background-color: #000000;
    height: 82px;
}

#logo {
    display: block;
    float: left;
    padding-left: 5vw;
    padding-right: 3vw;
    text-align: center;
}

/* Since we'll have the "ul li" "float:left"
 * we need to add a clear after the container. */

nav:after {
    content: "";
    display: table;
    clear: both;
}

/* Removing padding, margin and "list-style" from the "ul",
 * and adding "position:reltive" */
nav ul {
    float: right;
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
}

/* Positioning the navigation items inline */
nav ul li {
    margin: 0px;
    display: inline-block;
    z-index: 999;
    background-color: #000000;
}

nav ul li ul li {
    background-color: #474747;
}

/* Styling the links */
nav a {
    display: block;
    padding: 18px 12px;
    color: #FFF;
    font-size: 17px;
    text-decoration: none;
}


nav ul li ul li:hover {
    background: #000000;
}

/* Background color change on Hover */
nav a:hover {
    //background-color: #000000; 
    color: #dda63a;
}

/* Hide Dropdowns by Default
 * and giving it a position of absolute */
nav ul ul {
    display: none;
    position: absolute;
    /* has to be the same number as the "line-height" of "nav a" */
    top: 60px;
}

/* Display Dropdowns on Hover */
nav ul li:hover>ul {
    display: inherit;
}

/* Fisrt Tier Dropdown */
nav ul ul li {
    width: 170px;
    float: none;
    display: list-item;
    position: relative;
}

/* Second, Third and more Tiers	
 * We move the 2nd and 3rd etc tier dropdowns to the left
 * by the amount of the width of the first tier.
*/
nav ul ul ul li {
    position: relative;
    top: -60px;
    /* has to be the same number as the "width" of "nav ul ul li" */
    left: 170px;
}


/* Change ' +' in order to change the Dropdown symbol */
li>a:after {
    content: '';
}

li>a:only-child:after {
    content: '';
}


/* Media Queries
--------------------------------------------- */
@media all and (max-width : 1515px) {

    #logo {
        display: block;
        float: left;
        padding-left: 5vw;
        padding-right: 3vw;
        text-align: center;
    }

    nav a {
        display: block;
        padding: 18px 2px 18px 2px;
        color: #FFF;
        font-size: 17px;
        text-decoration: none;
    }
}

@media all and (max-width : 1244px) {

    #logo {
        display: block;
        float: left;
        padding-left: 5vw;
        padding-right: 3vw;
        text-align: center;
    }
}



@media all and (max-width : 1220px) {

    .header-dash {
        position: absolute;
        z-index: 999;
        top: 0;
        left: 0;
        right: 0;
        min-width: 250px;
    }

    #menu-title {
        float: left;
    }

    #logo {
        display: block;
        padding: 0;
        width: 100%;
        text-align: center;
        float: none;
        background-color: #000;
        margin: 0;
    }

    #menu-bar {
        position: absolute;
        margin-top: 63px;
    }

    #menu-logout {
        display: none;
    }

    #mobile-logout {
        display: block;
        float: right;
        padding: 0px 0px 0px 0px;
        margin-top: 2vh;
    }

    nav {
        z-index: 999;
        margin: 0;
        height: 63px;
    }

    /* Hide the navigation menu by default */
    /* Also hide the  */
    .toggle+a,
    .menu {
        margin-left: 0;
        display: none;
        float: left;
    }

    /* Styling the toggle label */
    .toggle {
        display: flex;
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        background-color: #000000;
        padding: 12px 15px;
        color: #FFF;
        font-size: 16px;
        text-decoration: none;
        border: none;
    }

    .toggle:hover {
        color: #dda63a;
    }

    .menu-btn {
        display: flex;
        background-color: #000000;
        padding: 12px 15px;
        color: #FFF;
        font-size: 17px;
        text-decoration: none;
        border: none;

        &:hover {
            color: #dda63a;
        }

        &:focus {
            outline: none;
        }
    }

    #menu-icon {
        width: 25px;
    }

    /* Display Dropdown when clicked on Parent Lable */
    [id^=drop]:checked+ul {
        display: block;
    }

    /* Change menu item's width to 100% */
    nav ul li {
        display: block;
        width: 100%;
    }

    nav ul ul .toggle,
    nav ul ul a {
        padding: 0 40px;
    }

    nav ul ul ul a {
        padding: 0 80px;
    }

    nav a:hover,
    nav ul ul ul a {
        background-color: #000000;
    }

    nav ul li ul li .toggle,
    nav ul ul a,
    nav ul ul ul a {
        padding: 14px 20px;
        color: #FFF;
        font-size: 17px;
    }


    nav ul li ul li .toggle,
    nav ul ul a {
        background-color: #212121;
    }

    /* Hide Dropdowns by Default */
    nav ul ul {
        float: none;
        position: static;
        color: #ffffff;
        /* has to be the same number as the "line-height" of "nav a" */
    }

    /* Hide menus on hover */
    nav ul ul li:hover>ul,
    nav ul li:hover>ul {
        display: none;
    }

    /* Fisrt Tier Dropdown */
    nav ul ul li {
        display: block;
        width: 100%;
    }

    nav ul ul ul li {
        position: static;
        /* has to be the same number as the "width" of "nav ul ul li" */

    }

    /* Hamburger Icon */
    .container {
        display: inline-block;
        cursor: pointer;
    }

    .bar1,
    .bar2,
    .bar3 {
        width: 35px;
        height: 5px;
        background-color: rgb(255, 255, 255);
        margin: 6px 0;
        transition: 0.4s;
    }

    .change .bar1 {
        -webkit-transform: rotate(-45deg) translate(-9px, 6px);
        transform: rotate(-45deg) translate(-9px, 6px);
    }

    .change .bar2 {
        opacity: 0;
    }

    .change .bar3 {
        -webkit-transform: rotate(45deg) translate(-8px, -8px);
        transform: rotate(45deg) translate(-8px, -8px);
    }

    .subdomain {
        position: absolute;
        top: 131px;
        left: 0px;
        right: 0;
        bottom: 0;
        background-color: white;
        transition: 0.5s;
    }

    .subdomain iframe {
        display: block;
        width: 100%;
        height: 100%;
        border: none;
    }

}



@media all and (max-width : 330px) {

    nav ul li {
        display: block;
        width: 94%;
    }



}