.base-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 1vmax;
    min-width: 400px;
    padding: 2vh 1vw 2vh;
    border-top: 7px solid #dda63a;
    border-bottom: 7px solid #dda63a;

    .row {
      display: inline;
    }

    @media only screen and (min-width: 768px){
      .row {
        display: flex;
      }
    }

    .horLine {
      border-top: 1px solid rgb(212, 212, 212);
    }

    .inputTitle {
      font-weight: bold;
    }

    .ippImg {
      width: 100px;
    }

    .column {
      width: 75%;
    }

    .image {
      align-items: center;
      img {
        width: 80%;
        height: 80%;
      }
    }
    
    .error {
      font-family: "Open Sans", sans-serif;
      font-size: 24px;
      font-weight: 900;
      color: #00a200;
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: 5px;
            
      .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        
  
        .form-group {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: fit-content;
          
          input {
            min-width: 290px;
            height: 40px;
            padding-left: 10px;
            font-size: 16px;
            font-weight: normal;
            font-family: "Open Sans", sans-serif;
            background-color: #ffffff;
            border: 1px solid rgb(212, 212, 212);
            margin-bottom: 2%;
            transition: all 250ms ease-in-out;
            &:hover {
              background-color: #cdeded;
            }
  
            &:focus {
              outline: none;
              box-shadow: px 0px 12px 0.8px #3474dbb2;
            }
          }
        }
      }
    }
    
    .footer {
      margin-bottom: 1vh;
    }
  }

  .errorSpacer {
    padding-bottom: 22px;
  }

  .loginerror {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 900;
    color: rgb(185, 0, 0);
    opacity: 1;
    padding-bottom: 5px;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.25s;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .sent {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 900;
    color: rgb(0, 89, 255);
    padding-bottom: 1vh;
  }

  .App {
    height: 100vh;
    width: 100vw;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    font-family: "Open Sans", sans-serif;    
  }

  .base-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .btn {
    font-size: 14px;
    width: 100%;
    padding: 10px 40px;
    border: 0;
    margin-top: 2px;
    margin-bottom: 4px;
    background-color: #dda63a;
    color: #fff;
    border-radius: 7px;
    transition: all 250ms ease-in-out;
    cursor: pointer;
    &:hover {
      background-color: #b18225;
    }
    &:focus {
      outline: none;
      background-color: #dda63a;
    }
    &:disabled {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
        cursor: not-allowed;
    }
  }

  .forgot {
    font-weight: bold;
    color: #2490fd;
    background:none;
    cursor: pointer;
    border: none;
    border-width : 0;
    border : 0;
    &:hover {
      color: #126cc7;
    }
  }

  .logoButton {
    background:none;
    cursor: pointer;
    border: none;
    border-width : 0;
    border : 0;
  }

  